import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Countdown from "../components/countdown"
import heartImage from "../images/Heart@2X.png"
import flowerImage from "../images/Flor.png"
import kneeImage from "../images/knee.png"
import inviteImage from "../images/invite.png"
import chirchImage from "../images/chirch.png"
import partyImage from "../images/party.png"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: this.getQueryString("email"),
      radioSelected: "",
    }
    this.getQueryString = this.getQueryString.bind(this)
    this.radioSelected = this.radioSelected.bind(this)
  }
  getQueryString(key) {
    var query =
      typeof window !== "undefined" &&
      window.location.search.substr(1).split("&")
    while (query.length) {
      var string = query.pop().split("=")
      if (string[0] === key) {
        return string[1]
      }
    }
  }
  radioSelected(selected) {
    this.setState({ radioSelected: selected })
  }
  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <img src={flowerImage} className="flor" alt="" />
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "Banner.png" }) {
                childImageSharp {
                  fluid(maxWidth: 1140) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          `}
          render={data => (
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              style={{ width: "100%", marginTop: -38 }}
            />
          )}
        />
        <h1 className="title red" style={{ marginTop: 19 }}>
          3 de agosto de 2019
        </h1>
        <i style={{ marginTop: 30, marginBottom: 24 }} />
        <p style={{ marginBottom: 11 }}>IGREJA PAROQUIAL DE CARCAVELOS</p>
        <strong className="great-vibes">
          Praça da República, 2775-624 Carcavelos
        </strong>
        <div
          className="grey"
          style={{ marginTop: 52, paddingTop: 60, paddingBottom: 20 }}
        >
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1140,
              padding: `0px 0 1.45rem`,
              paddingTop: 0,
            }}
          >
            <img src={kneeImage} style={{ width: 280 }} alt="" />
            <p
              style={{
                fontSize: 36,
                color: "#000000",
                fontFamily: "Montserrat",
                fontWeight: 700,
                textAlign: "center",
                textTransform: "uppercase",
                marginTop: 30,
                lineHeight: "40px",
              }}
            >
              O João ajoelhou... A contagem começou!
            </p>
            <Countdown />
            <Link to="curiosidades" className="btn">
              Saber mais
            </Link>
          </div>
        </div>
        <div className="dots">
          <div
            className="container"
            style={{
              margin: `0 auto`,
              maxWidth: 1140,
              padding: `0`,
              paddingTop: 0,
            }}
          >
            <div className="col" style={{}}>
              <img
                src={inviteImage}
                style={{
                  width: "100%",
                  maxWidth: 550,
                  marginTop: -46,
                  marginBottom: -45,
                }}
                alt=""
              />
            </div>
            <div className="col" style={{ padding: "45px 0 0" }}>
              <img src={heartImage} width={99} alt="" />
              <h1 className="title" style={{ marginBottom: 22 }}>
                Junte-se a nós
              </h1>
              Por favor, confirmar até ao dia 20 de junho.
              <Link to="convidados" className="btn">
                RSPV
              </Link>
            </div>
          </div>
        </div>
        <div className="black" style={{ paddingTop: 23 }}>
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1140,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0,
            }}
          >
            <h1 style={{ marginBottom: 38 }}>Onde e quando</h1>
            <div className="container">
              <div className="col">
                <img src={chirchImage} style={{ width: 75 }} alt="" />
                <p
                  style={{
                    textTransform: "uppercase",
                    fontSize: 24,
                    marginBottom: 51,
                  }}
                >
                  A cerimónia
                </p>
                <p style={{ marginBottom: 24 }}>
                  Sábado, 3 de agosto 2019
                  <br /> 11h45 — 12h45
                </p>{" "}
                <p>
                  Igreja Paroquial de Carcavelos
                  <br />
                  Praça da República
                  <br />
                  2775-624 Carcavelos
                </p>
              </div>
              <div className="col">
                <img src={partyImage} style={{ width: 68 }} alt="" />
                <p
                  style={{
                    textTransform: "uppercase",
                    fontSize: 24,
                    marginBottom: 51,
                  }}
                >
                  A festa
                </p>
                <p style={{ marginBottom: 24 }}>
                  Sábado, 3 de agosto 2019
                  <br /> 13h30 — 01h00
                </p>{" "}
                <p>
                  Quinta da Lapa
                  <br />
                  Largo da Ponte 2<br />
                  2710-125 Sintra
                </p>
              </div>
            </div>
            <Link to="informacoes" className="btn">
              SABER MAIS
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
