import React, { Component } from "react"
import { graphql } from "gatsby"

const styles = {
  numbersGrey: {
    fontSize: "68px",
    lineHeight: "70px",
    color: "rgba(235,235,235)",
    fontFamily: "Montserrat",
    fontWeight: 700,
    textAlign: "center",
  },
  numbers: {
    fontSize: "68px",
    lineHeight: "70px",
    color: "#6a6a6a",
    fontFamily: "Montserrat",
    fontWeight: 700,
    textAlign: "center",
  },
  labels: {
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontWeight: 300,
    textAlign: "center",
  },
}

class Countdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSelected: props.isSelected === props.id,
    }
    var countDownDate = new Date("Aug 3, 2019 12:00:00").getTime()
    var x = setInterval(
      function() {
        // Get todays date and time
        var now = new Date().getTime()

        // Find the distance between now and the count down date
        var distance = countDownDate - now

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = String(Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ))
        var minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
        var seconds = String(Math.floor((distance % (1000 * 60)) / 1000))

        this.setState({
          days: days,
          hours: `0${hours}`.substr(-2),
          minutes: `0${minutes}`.substr(-2),
          seconds: `0${seconds}`.substr(-2),
        })

        // If the count down is finished, write some text
        // if (distance < 0) {
        //   clearInterval(x);
        //   document.getElementById("demo").innerHTML = "EXPIRED";
        // }
      }.bind(this),
      1000
    )
    this.state.timer = x
  }
  componentWillUnmount() {
    clearInterval(this.state.timer)
  }
  render() {
    return (
      <div className="countdown">
        <p style={styles.numbers}>
          {this.state.days}
          <span style={styles.labels}>DIAS</span>
        </p>
        <p style={styles.numbersGrey}>
          {this.state.hours}
          <span style={styles.labels}>HORAS</span>
        </p>
        <p style={styles.numbersGrey}>
          {this.state.minutes}
          <span style={styles.labels}>MINUTOS</span>
        </p>
        <p style={styles.numbersGrey}>
          {this.state.seconds}
          <span style={styles.labels}>SEGUNDOS</span>
        </p>
      </div>
    )
  }
}
export const pageQuery = graphql`
  query {
    fluidImages: file(
      relativePath: { regex: "/knee.png/" }
    ) {
      childImageSharp {
        fluid(
          duotone: { highlight: "#f00e2e", shadow: "#192550" }
          traceSVG: {
            color: "#f00e2e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          toFormat: PNG
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    fluid: file(
      relativePath: { regex: "/knee.png/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 120
          duotone: { highlight: "#0ec4f1", shadow: "#192550" }
          traceSVG: { color: "#1E2151" }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
export default Countdown
